<template>
  <div id="iFrame" class="noScrollup">


    <div id="container-iFrame" class="">
<!--
        <iframe id="blockGenially" name="iframe" src="https://view.genial.ly/63d7f7679451bb00117d00eb" scrolling="auto" title="La boîte à outils Orient'Est" class="wrapper" width="100%" height="1600" frameborder="0">
 -->
        <iframe id="blockGenially" name="iframe" src="https://view.genial.ly/63d7f7679451bb00117d00eb" scrolling="auto" title="La boîte à outils Orient'Est" class="wrapper" width="100%" height="1820" frameborder="0">
		  Cette option ne fonctionnera pas correctement. Malheureusement, votre navigateur ne supporte pas les frames.
        </iframe>

    </div>

  </div>
</template>


<script>


export default {
    name: 'iFrame',
    data() {
        return {
            //toto:false,
                messageTxt:"",
                messageOK:true,
                message:false,
                myMessageTimeout:null,

            ready:false,

        }
    },
    components: {
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    created() {

    },
    mounted() {
    },

    computed: {
        sitePRO() {
            return(this.$parent.sitePRO);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        lesParams() {
            return(this.$route.params.pere);
        },

        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },
    methods: {

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">


    .addeoMedium {
        #iFrame {
            #container-iFrame {
                #blockGenially {
                    max-height: 1380px;
                }
            }
        }
    }
    .addeoSmall {
        #iFrame {
            #container-iFrame {
                #blockGenially {
                    max-height: 900px;
                }
            }
        }
    }

    #iFrame {

        $couleur-fond: #eee;
        $couleur-texte: #223938;

        #container-iFrame {
            text-align: center;

            h3 {
                color:#006EAB;
                font-size: 1.6em;
            }


        }
    }



html {
    body {
        #app.addeoSmall, #app.addeoMedium, #app.addeoLarge  {

            .scrollup {
                display:none;
            }
        }
    }
}



/**/
    @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
    /**/


    /* reprise matérial design : 2022-02 */

    #blocVersion {
        display:none;
    }

    #iFrame {

        #container-iFrame {
            font-family: Roboto, Arial, sans-serif;
            $couleur-fond: #eee;
            $couleur-texte: #223938;

            text-align: center;
            width: 100%;

        }


    }


</style>
